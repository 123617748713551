/* eslint-disable sort-keys */
/* eslint-disable import/order */

import Eth from "@ledgerhq/hw-app-eth";
import EthTx from 'ethereumjs-tx';
import { LEDGER_TRANSPORT_TIMEOUT } from '../constants';
import TransportWebHID from "@ledgerhq/hw-transport-webhid";
import { formatResult } from '../helpers/errorUtils';
import mapSeries from 'promise-map-series';
import { addHexPrefix, rlp } from 'ethereumjs-util';
import {
  getRawTx,
  getTxHash,
  sanitizeTxData,
} from '../helpers/transactionUtils';

let transport = null;

const LedgerWallet = {
  init: async (customTimeout) => {
    const timeout = customTimeout || LEDGER_TRANSPORT_TIMEOUT;
    try {
      if (!transport) {
        transport = await TransportWebHID.create();
      }

      const ethTransport = new Eth(transport);
      await ethTransport.getAppConfiguration(); // if config fails, show error to re-connect ledger

      return formatResult({ ethTransport });
    } catch (error) {
      transport = null;

      return formatResult(null, error);
    }
  },

  getAddress: async (ethTransport, hdPath) => {
    try {
      const result = await ethTransport.getAddress(hdPath);
      return formatResult(result);
    } catch (error) {
      return formatResult(null, error);
    }
  },

  getAddresses: async (
    ethTransport,
    hdPath,
    limit = 5,
    offset = 0,
  ) => {
    try {
      const paths = [];
      const index = offset * limit;

      for (let i = index; i < index + limit; i += 1) {
        paths.push(`${hdPath}/${i}`);
      }

      const result = await mapSeries(paths, (path) => ethTransport.getAddress(path));
      return formatResult(result);
    } catch (error) {
      return formatResult(null, error);
    }
  },

  signEthTransaction: async (ethTransport, path, txData, chainId) => {
    try {
      const sanitizedParams = await sanitizeTxData(txData);
      sanitizedParams.chainId = chainId;

      const { raw } = new EthTx(sanitizedParams);

      // unsigned v, r, s values
      raw[6] = Number(chainId) || 1;
      raw[7] = 0;
      raw[8] = 0;

      const rawTxHex = rlp.encode(raw).toString('hex');
      const result = await ethTransport.signTransaction(path, rawTxHex);

      // TODO: Add EIP155 support for chainId > 255 (currently not applicable to electron)
      // Issue Ref: https://github.com/LedgerHQ/ledgerjs/issues/168
      // Implementation Ref: https://github.com/LedgerHQ/ledgerjs/issues/168#issuecomment-408716057

      const { v, r, s } = result;
      const signature = {
        r: addHexPrefix(r),
        s: addHexPrefix(s),
        v: addHexPrefix(v),
      };

      const signedTxParams = {
        ...sanitizedParams,
        ...signature,
      };

      return formatResult({
        rawTransaction: getRawTx(signedTxParams, true),
        transactionHash: getTxHash(signedTxParams, true),
        signature,
      });
    } catch (error) {
      return formatResult(null, error);
    }
  },
};

export default LedgerWallet;
