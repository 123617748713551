import EthTx from 'ethereumjs-tx';
import { toHex } from 'web3-utils';

// Do not return gas param (will cause an error for trezor and ledger)
// Set gasLimit to the value of gas instead
export function sanitizeTxData(txData) {
  return {
    data: txData.data,
    from: txData.from,
    gasLimit: toHex(txData.gas),
    gasPrice: toHex(txData.gasPrice),
    nonce: toHex(txData.nonce),
    to: txData.to,
    value: txData.value
      ? toHex(txData.value)
      : '0x0',
  };
}

export function getRawTx(txParams, addPrefix) {
  const tx = new EthTx(txParams);
  const rawTx = tx.serialize().toString('hex');

  if (addPrefix) {
    return `0x${rawTx}`;
  }

  return rawTx;
}

export function getTxHash(txParams, addPrefix) {
  const tx = new EthTx(txParams);
  const txHash = tx.hash(true).toString('hex');

  if (addPrefix) {
    return `0x${txHash}`;
  }

  return txHash;
}
