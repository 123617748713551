import { ethers } from 'ethers';

const JsonWallet = {
  getPrivateKey: async (fileContent, password) => {
    const { privateKey } = await ethers.Wallet.fromEncryptedJson(fileContent, password);
    return privateKey;
  },
};

export default JsonWallet;
