import util from 'ethereumjs-util';
import web3Utils from 'web3-utils';

export const {
  padLeft,
  padRight,
  toHex,
  toDecimal,
  fromDecimal,
  toUtf8,
  toAscii,
  fromUtf8,
  fromAscii,
  transformToFullName,
  extractDisplayName,
  extractTypeName,
  toWei,
  fromWei,
  toBN,
  toTwosComplement,
  toAddress,
  isBigNumber,
  isStrictAddress,
  isAddress,
  isChecksumAddress,
  toChecksumAddress,
  isFunction,
  isString,
  isObject,
  isBoolean,
  isArray,
  isJs,
} = web3Utils;

export function capitalize(str) {
  return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
}

export function toTitleCase(str) {
  return str ? str.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ') : '';
}

export function toLocatorCase(str) {
  return str ? str.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join('-') : '';
}

export function parseDate(num, seconds = false, method = 'toLocaleDateString') {
  if (!num) {
    return '-';
  }
  return new Date(num * (seconds ? 1000 : 1))[method]();
}

export function parseBigNumber(_bigNumber, shift = 0, format) {
  const bigNumber = toBN(_bigNumber);
  if (!bigNumber || !bigNumber.equals || bigNumber.equals(0)) {
    return 0;
  }
  const num = bigNumber.shift(-shift);
  if (format === false) {
    return num.toNumber();
  }
  const str = num.toFormat();
  const decimals = str.split('.')[1];
  const decimalCount = decimals && decimals.length;
  const maxDecimals = format || 4;
  if (decimalCount > maxDecimals) {
    return `${num.toFormat(maxDecimals)}...`;
  }
  return num.toFormat();
}

export function addToHex(hex, value) {
  return toHex(toBN(hex).plus(value));
}

export function parseBigNumberDate(bigNumber) {
  return parseDate(parseBigNumber(bigNumber, 0, false), true);
}

export function isPrefixed(str = '') {
  return str.slice(0, 2) === '0x';
}

export function dePrefix(str = '') {
  if (isPrefixed(str)) {
    return str.slice(2);
  }
  return str;
}

export function addHexPrefix(str) {
  return util.addHexPrefix(str);
}

export function removeHexPrefix(str) {
  return addHexPrefix(str).slice(2);
}

export function lowercaseUnprefixedHex(str) {
  return removeHexPrefix(str).toLowerCase();
}

export function getTimestamp(timestamp) {
  const ts = timestamp ? new Date(timestamp) : new Date();
  return ts.toJSON().replace(/:/g, '-');
}

export function getV3FileName(address) {
  return `UTC--${getTimestamp()}--${lowercaseUnprefixedHex(address)}`;
}

export function isNull(str) {
  if (!str) {
    return true;
  }
  if (typeof str !== 'string') {
    throw new Error('Only accepts string types');
  }
  const hex = lowercaseUnprefixedHex(str);
  return !!hex.match(/^[0]*$/);
}

export function isNotNull(str) {
  return !isNull(str);
}

// converts 0x00...00 to null
export function nullify(str) {
  return isNull(str) ? null : str;
}

export function parseHex(string) {
  try {
    return toUtf8(string);
  } catch (e) {
    return toAscii(string);
  }
}

export function toRenderable(str) {
  if (isBigNumber(str)) {
    return parseBigNumber(str);
  }
  const string = `${str}`;
  if (isPrefixed(string)) {
    return parseHex(string);
  }
  return string;
}

export function shortAddress(address, num = 10, showEnd = true) {
  return `${address.slice(0, num)}...${showEnd ? address.slice(-num) : ''}`;
}

export function camelToCapitalized(str) {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());
}

export function formatQuery(obj) {
  const queryStr = Object.keys(obj)
    .reduce((a, k) => a.concat(`${k}=${encodeURIComponent(obj[k])}`), [])
    .join('&');
  return `?${queryStr}`;
}

export function parseQuery(qstr) {
  const query = {};
  const a = (qstr[0] === '?' ? qstr.substr(1) : qstr).split('&');
  for (let i = 0; i < a.length; i += 1) {
    const b = a[i].split('=');
    query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || '');
  }
  return query;
}

export const isVersionOutdated = (currentVersion, latestVersion) => {
  const semver = currentVersion.split('.');
  const latestSemver = latestVersion.split('.');
  return (
    Number(semver[0]) < latestSemver[0]
    || Number(semver[1]) < latestSemver[1]
    || Number(semver[2]) < latestSemver[2]
  );
};

export const truncateNumber = (number, decimals = 2) => {
  if (number !== null) {
    return (Math.floor(number * (10 ** decimals)) / (10 ** decimals)).toFixed(decimals);
  }
  return 'N/A';
};
