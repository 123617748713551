/* eslint-disable sort-keys */

// NOTE: from the docs (https://github.com/trezor/connect/blob/develop/docs/methods.md)
// TrezorConnect methods will always resolve, so there's no need for Promise.catch
import TrezorConnect from 'trezor-connect';
import { formatResult } from '../helpers/errorUtils';
import {
  getRawTx,
  getTxHash,
  sanitizeTxData,
} from '../helpers/transactionUtils';

TrezorConnect.manifest({
  appUrl: 'https://digix.global/',
  email: 'francismurillo@digixglobal.com',
});

const formatError = (payload) => (
  formatResult(null, {
    message: payload.error,
  })
);

const formatResponse = (result) => {
  const { success, payload } = result;
  if (!success) {
    return formatError(payload);
  }

  return formatResult(payload);
};

const TrezorWallet = {
  getPublicKey: async (hdPath) => {
    const options = {
      coin: 'eth',
      path: hdPath,
    };

    const result = await TrezorConnect.getPublicKey(options);
    return formatResponse(result);
  },

  getAddress: async (hdPath) => {
    const result = await TrezorConnect.ethereumGetAddress({ path: hdPath });
    return formatResponse(result);
  },

  getAddresses: async (hdPath, limit = 5, offset = 0) => {
    const paths = [];
    const index = offset * limit;
    for (let i = index; i < index + limit; i += 1) {
      paths.push({
        path: `${hdPath}/${i}`,
        showOnTrezor: false,
      });
    }

    const result = await TrezorConnect.ethereumGetAddress({ bundle: paths });
    return formatResponse(result);
  },

  signEthTransaction: async (path, txData, chainId) => {
    const sanitizedParams = sanitizeTxData(txData);
    sanitizedParams.chainId = chainId;

    const { payload, success } = await TrezorConnect.ethereumSignTransaction({
      path,
      transaction: sanitizedParams,
    });

    if (!success) {
      return formatError(payload);
    }

    const signedTxParams = {
      ...sanitizedParams,
      ...payload,
    };

    return formatResult({
      rawTransaction: getRawTx(signedTxParams, true),
      transactionHash: getTxHash(signedTxParams, true),
      signature: payload,
    });
  },
};

export default TrezorWallet;
