export const formatResult = (payload, errorParams) => {
  const data = payload || {};
  if (!errorParams) {
    return {
      data,
      error: null,
    };
  }

  const {
    message,
    originalError,
    statusCode,
    statusText,
  } = errorParams;

  return {
    data,
    error: {
      message,
      metaData: originalError ? originalError.metaData : null,
      statusCode,
      statusText,
    },
  };
};

export default {
  formatResult,
};
