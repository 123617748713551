/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

export const LEDGER_TRANSPORT_TIMEOUT = 10000;
export const LATEST_ETHEREUM_APP_LEDGER = '1.2.4';
export const LEDGER_ETH_KD_PATH = "44'/60'/0'";
export const LEDGER_HD_PATHS = [
  {
    name: `${LEDGER_ETH_KD_PATH} (Ledger Default)`,
    value: LEDGER_ETH_KD_PATH,
  },
  {
    name: "44'/60'/1' (Account #2)",
    value: "44'/60'/1'",
  },
  {
    name: "44'/60'/2' (Account # 3)",
    value: "44'/60'/2'",
  },
];

export const TREZOR_ETH_KD_PATH = "m/44'/60'/0'/0";
export const TREZOR_HD_PATHS = [
  {
    name: `${TREZOR_ETH_KD_PATH} (Trezor Default)`,
    value: TREZOR_ETH_KD_PATH,
  },
  {
    name: "m/44'/60'/0'/1' (Account #2)",
    value: "m/44'/60'/0'/1'",
  },
  {
    name: "m/44'/60'/0'/2 (Account # 3)",
    value: "m/44'/60'/0'/2",
  },
];
